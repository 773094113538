import React from 'react'
import './possibility.css'
import possibilityImage from '../../assets/5.png'

const Possibility = () => {
  return (
    <div className="juan__possibility section__padding" id="possibility">
    <div className="juan__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="juan__possibility-content">
      <h4>Experience The Best Service</h4>
      <h1 className="gradient__text">The Past, Present, and <br /> The Future</h1>
      <p>At the beginning of our business, we only had a partnership with 1 company. Having gradually expanded our business over different countries, now we have foundational, trustworthy, and reliable partnerships with 12 companies from 7 different countries.
        It is never too late to grow your company with us.
      </p>
      <h4>Your Success is Our Success</h4>
    </div>
  </div>
  )
}

export default Possibility