import React from 'react'
import './features.css'
import { ProductFeature } from '../../components'
import product1 from '../../assets/product1.jpeg'
import product3 from '../../assets/product3.jpeg'
import product5 from '../../assets/product5.jpeg'
import product6 from '../../assets/product6.jpeg'

const featuresData = [
  {
    title: 'Shock Absorber',
    img: product1,
  },
  {
    title: 'Drag Link',
    img: product3,
  },
  {
    title: 'Baby Goods',
    img: product5,
  },
  {
    title: 'Drug goods',
    img: product6,
  },
];

const Features = () => {
  return (
    <div className="juan__features section__padding" id="products">
    <div className="juan__features-heading">
      <h1 className="gradient__text">Check out our representative products.</h1>
      <p>We supply all kinds of auto spare parts, primarily OEM, after market goods, and genuine parts (MOBIS) such as shock absorber, water pump, oil pump, piston, suspension parts.</p>
      <br />
      <p>Besides, we can supply baby goods, drug goods, and k-beauty products (face mask sheet, aloe gel, and etc) from all kinds of brands (The Face Shop, Nature Republic, CLIO, and etc).</p>
    </div>
    <div className="juan__features-container">
      {featuresData.map((item, index) => (
        <ProductFeature title={item.title} img={item.img} key={item.title + index} />
      ))}
    </div>
  </div>
  )
}

export default Features