import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <div className="juan__footer section__padding" id="contact">
    <div className="juan__footer-copyright">
      <div className="juan__footer-heading">
        <h1>Contact Info</h1><br />
        <p># 957-29, DAPSIMNI-DONG, DONGDAEMUN-GU, SEOUL, KOREA</p><br />
        <p>TEL: 82-10-6662-4581</p><br />
        <p>FAX: 82-2-6971-8254</p><br />
        <p>E-MAIL : hamiram@naver.com</p><br />
      </div>
    </div>
    <p className='juan__footer-copyright-text'>@2023 JUAN. All rights reserved.</p>
  </div>
  )
}

export default Footer