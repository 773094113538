import React from 'react'
import './whatjuan.css'
import { Feature } from '../../components'

const WhatJuan = () => {
  return (
    <div className="juan__whatjuan section__margin" id="wjuan">
    <div className="juan__whatjuan-feature">
      <Feature title="What is Juan" text="Beyond the limit of Korean car parts, Juan Sangsa focuses on covering not only Korean applications but also Foreign applications such as European and Japanese in order to serve our customers with one stop service." />
    </div>
    <div className="juan__whatjuan-heading">
      <h1 className="gradient__text">The customer&apos;s satisfaction is our #1 priority.</h1>
      <p>Explore the benefits</p>
    </div>
    <div className="juan__whatjuan-container">
      <Feature title="Variety" text="We have expanded our business to export not only automotive parts, but also medicine, baby products, cosmetics, and so on." />
      <Feature title="Quickness" text="If you send us any inquiries, we will reply with the best quality goods as soon as possible." />
      <Feature title="Negotiation" text="Our goods come directly from factories, which makes us enable to negotiate the most reasonable price with our customer." />
    </div>
  </div>
  )
}

export default WhatJuan