import React from 'react'
import './header.css'
import people from '../../assets/people.png';
import headImage from '../../assets/3.jpg'

const Header = () => {
  return (
    <div className="juan__header section__padding" id="home">
    <div className="juan__header-content">
      <h1 className="gradient__text">The Leader in Korean Automotive Spare Parts Business</h1>
      <p>The service we are providing is always used to solve customer&apos;s needs of searching reliable supplier
        on spare parts and it is attributed to our well-organized Purchase Network and Sales Network.</p>

      <div className="juan__header-content__people">
        <img src={people} alt="people" />
        <p>More than 1,600 customers have ordered since 2010.</p>
      </div>
    </div>

    <div className="juan__header-image">
      <img src={headImage} alt="export" />
    </div>
  </div>
  )
}

export default Header