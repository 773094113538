import React from 'react'
import './productFeature.css'

const ProductFeature = ({ title, img }) => {
  return (
    <div className="juan__features-container__feature">
    <div className="juan__features-container__feature-title">
        <div />
        <h1>{title}</h1>
    </div>
    <div>
        <img src={img} alt={title} className='juan__features-container_img' />
    </div>
  </div>
  )
}

export default ProductFeature